<template>
  <v-row justify="center">
    <v-dialog v-model="menu" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on">
          <v-list-item-content>
            <v-list-item-title> {{ tipo }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-card v-if="tipo === 'Educação Infantil'">
        <v-card-title>
          <span class="text-h5">{{ tipo }}</span>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item @click="gerarAtaFinalInfantil(gestao.turma_id)">
              <v-list-item-icon>
                <v-icon small>visibility</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Ata Final da Turma - {{ tipo }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              :to="{
                name: 'relatorios.visualizar',
                params: {
                  turmaId: gestao.turma_id,
                  gestaoDeAulasId: gestao.id,
                },
              }"
            >
              <v-list-item-icon>
                <v-icon small>visibility</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Lançar Notas - Infantil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="verAulasInfantil(gestao.id)">
              <v-list-item-icon>
                <v-icon small>visibility</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <!-- <v-list-item-title> <a :href="`gestoes-de-aulas/${gestao.id}/aulas?infantil=true`">Aulas</a>  </v-list-item-title> -->
                <v-list-item-title>Aulas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{
                name: 'gestoesDeAulas.planoMensalInfantil',
                params: { gestaoDeAulaId: gestao.id },
              }"
            >
              <v-list-item-icon>
                <v-icon small>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Criar Plano Mensal Infantil </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="
                () => {
                  tipoEnsino = 'infantil';
                  escolherDisciplinaPDF(gestao.id, gestao.disciplina_id, 'planosMensaisPDF');
                }
              "
            >
              <v-list-item-icon>
                <v-icon small>fa fa-file-pdf</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Visualizar Plano Mensal Infantil </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :to="{
                name: 'gestoesDeAulas.metricasTurma',
                params: {
                  id: gestao.turma_id,
                  gestao: gestao.id,
                },
              }"
            >
              <v-list-item-icon>
                <v-icon small>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Métricas da turma </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="capaDiarioClasse(gestao.id)">
              <v-list-item-icon>
                <v-icon small>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Capa do Diário de Classe </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-menu open-on-hover right offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon small>fa fa-file-pdf</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Gerar plano bimestral infantil</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in $constants.bimestres" :key="index">
                  <v-list-item-title @click="gerarPlanoBimestralInfantil(gestao.id, item)">
                    {{ item.desc }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="menu = false"> Fechar </v-btn>
        </v-card-actions>

        <div v-if="dialogConfirmarAulas">
          <e-dialog-search-aulas-interval-date
            :gestao_selected_to_interval="gestao_selected_to_interval"
            :dialogConfirmarAulas="dialogConfirmarAulas"
            @dialogAulasChange="dialogConfirmarAulas = $event"
          >
          </e-dialog-search-aulas-interval-date>
        </div>
      </v-card>
      <v-card v-if="tipo === 'Ensino Fundamental'">
        <v-card-title>
          <span class="text-h5">{{ tipo }}</span>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item @click="ataFinal(gestao.turma_id)">
              <v-list-item-icon>
                <v-icon small>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Ata Final da Turma - {{ tipo }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="gerarBoletimGeral(gestao.turma_id)">
              <v-list-item-icon>
                <v-icon small>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Boletim Geral da Turma - {{ tipo }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="verAulas(gestao.id)">
              <v-list-item-icon>
                <v-icon small>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Aulas </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="gestao.is_polivalencia"
              @click="escolherDisciplina(gestao.id, gestao.disciplinas, 'notas')"
            >
              <v-list-item-icon>
                <v-icon small>visibility</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Lançar Notas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-else
              :to="{
                name: 'notas.circuitoNotas',
                params: {
                  gestaoDeAulasId: gestao.id,
                  disciplinaId: gestao.disciplina_id,
                },
              }"
            >
              <v-list-item-icon>
                <v-icon small>visibility</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Lançar Notas </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="
                if (gestao.is_polivalencia === 1) {
                  escolherDisciplina(gestao.id, gestao.disciplinas, 'planosMensais');
                } else {
                  $router.push({
                    name: 'gestoesDeAulas.planoMensalCreate',
                    params: {
                      gestaoDeAulaId: gestao.id,
                      disciplinaId: gestao.disciplina_id,
                    },
                  });
                }
              "
            >
              <v-list-item-icon>
                <v-icon small>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Criar Plano Mensal</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="
                if (gestao.is_polivalencia === 1) {
                  escolherDisciplinaMes(gestao.id, gestao.disciplinas);
                } else {
                  escolherDisciplinaPDF(gestao.id, gestao.disciplina_id, 'planosMensaisPDF');
                }
              "
            >
              <v-list-item-icon>
                <v-icon small>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Visualizar Plano Mensal </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              :to="{
                name: 'gestoesDeAulas.metricasTurma',
                params: {
                  id: gestao.turma_id,
                  gestao: gestao.id,
                },
              }"
            >
              <v-list-item-icon>
                <v-icon small>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Métricas da turma </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="capaDiarioClasse(gestao.id)">
              <v-list-item-icon>
                <v-icon small>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Capa do Diário de Classe </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="gestao.is_polivalencia"
              @click="
                escolherDisciplinaPlanoBimestral(
                  gestao.id,
                  gestao.disciplinas //Quando é Gestão Polivalente
                  // temPlanoBimestral(gestaoDeAulas.id)?.status
                )
              "
            >
              <v-list-item-icon>
                <v-icon small>fa fa-file-pdf</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Visualizar Plano Bimestral </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-else
              @click="
                escolherDisciplinaPlanoBimestral(
                  gestao.id,
                  gestao.disciplina //Quando NÃO é Gestão Polivalente
                  // temPlanoBimestral(gestaoDeAulas.id)?.status
                )
              "
            >
              <v-list-item-icon>
                <v-icon small>fa fa-file-pdf</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Visualizar Plano Bimestral </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="menu = false"> Fechar </v-btn>
        </v-card-actions>

        <e-escolha-disciplina
          @status="abrirModalEscolha = $event"
          :dialog="abrirModalEscolha"
          :gestaoDeAulasId="gestaoDeAulasEscolhida"
          :disciplinas="disciplinasArray"
          :typeModel="typeModel"
        ></e-escolha-disciplina>

        <div v-if="dialogConfirmarAulas">
          <e-dialog-search-aulas-interval-date
            :gestao_selected_to_interval="gestao_selected_to_interval"
            :dialogConfirmarAulas="dialogConfirmarAulas"
            @dialogAulasChange="dialogConfirmarAulas = $event"
          >
          </e-dialog-search-aulas-interval-date>
        </div>
        <e-escolha-disciplina-plano-bimestral
          @status="abrirModalEscolhaPlanoBimestral = $event"
          :dialog="abrirModalEscolhaPlanoBimestral"
          :gestaoDeAulasId="gestaoDeAulasEscolhida"
          :disciplinas="disciplinasArray"
        ></e-escolha-disciplina-plano-bimestral>

        <e-escolha-disciplina-mes
          @status="abrirModalEscolhaMes = $event"
          :dialog="abrirModalEscolhaMes"
          :gestaoDeAulasId="gestaoDeAulasEscolhida"
          :disciplinas="disciplinasArray"
          :typeModel="typeModel"
        ></e-escolha-disciplina-mes>
      </v-card>
    </v-dialog>
    <e-escolha-disciplina-plano-bimestral
      @status="abrirModalEscolhaPlanoBimestral = $event"
      :dialog="abrirModalEscolhaPlanoBimestral"
      :gestaoDeAulasId="gestaoDeAulasEscolhida"
      :disciplinas="disciplinasArray"
    ></e-escolha-disciplina-plano-bimestral>
    <e-escolha-disciplina-mes-pdf
      @status="abrirModalEscolhaMesPDF = $event"
      :dialog="abrirModalEscolhaMesPDF"
      :gestaoDeAulasId="gestaoDeAulasEscolhida"
      :disciplinaId="disciplinaEscolhida"
      :typeModel="typeModel"
      :tipoEnsino="tipoEnsino"
    ></e-escolha-disciplina-mes-pdf>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    tipoEnsino: "fundamental",
    abrirModalEscolhaMes: false,
    abrirModalEscolhaMesPDF: false,
    menu: false,
    disciplinasArray: [],
    gestaoDeAulasEscolhida: null,
    disciplinaEscolhida: null,
    abrirModalEscolhaPlanoBimestral: false,
    dialogConfirmarAulas: false,
    gestao_selected_to_interval: null,
    typeModel: null,
    abrirModalEscolha: false,
  }),

  props: {
    tipo: {
      type: String,
    },
    gestao: {
      type: Object,
    },
    turma: {
      type: Object,
    },
  },

  methods: {
    async gerarBoletimGeral(turmaId) {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.boletimGeral(parseInt(turmaId, 10));
      this.$loaderService.close();
    },
    async gerarAtaFinalInfantil(turmaId) {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.gerarAtaFinal(parseInt(turmaId, 10));
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
        this.$loaderService.close();
      }
    },

    async ataFinal(turmaId) {
      this.$loaderService.open("Carregando dados...");
      try {
        await this.$services.relatoriosService.resultadoPorDisciplina(parseInt(turmaId, 10));
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async gerarPlanoBimestralInfantil(gestaoId, item) {
      try {
        this.$loaderService.open("Gerando plano de aula...");
        await this.$services.relatoriosService.planoBimestralInfantil(
          parseInt(gestaoId, 10),
          parseInt(item.value, 10)
        );
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },
    verAulasInfantil(gestaoId) {
      // query: { search: 'vue', page: 1 }
      this.$router.push({
        name: "aulas",
        params: {
          gestaoDeAulaId: gestaoId,
        },
        query: { is_infantil_multi: "true" },
      });
    },
    escolherDisciplinaPDF(idGestao, disciplina_id, type) {
      this.abrirModalEscolhaMesPDF = true;
      // console.log("abrirModalEscolhaMesPDF: ", this.abrirModalEscolhaMesPDF);
      this.disciplinaEscolhida = disciplina_id;
      this.gestaoDeAulasEscolhida = idGestao;
      this.typeModel = type;
    },
    escolherDisciplina(idGestao, disciplinas, type) {
      this.abrirModalEscolha = true;
      this.disciplinasArray = disciplinas;
      this.gestaoDeAulasEscolhida = idGestao;
      this.typeModel = type;
    },

    escolherDisciplinaMes(idGestao, disciplinas, type) {
      this.abrirModalEscolhaMes = true;
      this.disciplinasArray = disciplinas;
      this.gestaoDeAulasEscolhida = idGestao;
      this.typeModel = type;
    },
    async capaDiarioClasse(gestaoId) {
      this.$loaderService.open("Gerando capa diario...");
      try {
        await this.$services.relatoriosService.capaDiarioClasse(parseInt(gestaoId, 10));
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    escolherDisciplinaPlanoBimestral(gestaoDeAulasId, disciplinas) {
      this.abrirModalEscolhaPlanoBimestral = true;
      this.disciplinasArray = typeof disciplinas === "string" ? [disciplinas] : disciplinas;
      this.gestaoDeAulasEscolhida = gestaoDeAulasId;
    },

    async verAulas(gestaoDeAulaID) {
      this.$router.push({
        name: "aulas",
        params: {
          gestaoDeAulaId: gestaoDeAulaID,
        },
        query: { is_infantil_multi: "false" },
      });
    },

    async gerarRelatorio() {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.listarTurma(parseInt(this.$route.params.turmaId, 10));
      this.$loaderService.close();
    },
  },
};
</script>
