<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Matriculas</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="criarMatricula" class="mr-2">Criar Matricula</v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="matriculasList"
          :loading="carregandoMatriculas"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="matricula.estaDeletado ? ['deleted'] : []"
                v-for="matricula of items"
                :key="matricula.id"
              >
                <td>{{ matricula.id }}</td>
                <th>
                  {{ matricula.aluno.codigo ? matricula.aluno.codigo : "- - -" }}
                </th>
                <td>{{ matricula.aluno.nomecompleto }}</td>
                <td class="text-left">
                  {{ matricula.turma.descricao }} [{{
                    matricula.serie ? matricula.serie.descricao : "- - -"
                  }}]
                </td>
                <td class="text-left">
                  <v-chip color="success" outlined>
                    {{ matricula.situacao ? matricula.situacao : "- - -" }}
                  </v-chip>
                </td>
                <td>
                  <div class="text-left">
                    <v-menu :close-on-content-click="false" :nudge-width="100" top offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small color="indigo" dark v-bind="attrs" v-on="on"> AÇÕES </v-btn>
                      </template>

                      <v-card class="mx-auto" max-width="300">
                        <v-list>
                          <v-list-item>
                            <v-list-item-avatar color="primary">
                              <v-list-item-title style="color: #fff">{{
                                matricula.aluno.nomecompleto
                                  ? matricula.aluno.nomecompleto.substring(0, 1).toUpperCase()
                                  : "- - -"
                              }}</v-list-item-title>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                matricula.aluno.nomecompleto
                                  ? matricula.aluno.nomecompleto
                                  : "- - -"
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >{{
                                  matricula.turma.descricao ? matricula.turma.descricao : "- - -"
                                }}
                                /
                                {{ matricula.aluno.codigo ? matricula.aluno.codigo : "- - -" }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <v-list>
                          <v-list-item-group v-if="!matricula.estaDeletado" color="primary">
                            <v-list-item @click="() => declaracaoCurso(matricula)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-file-pdf</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Declaração de curso</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>

                          <v-list-item-group v-if="!matricula.estaDeletado" color="primary">
                            <v-list-item @click="() => declaracaoConclusao(matricula)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-file-pdf</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Declaração de conclusão</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>

                          <v-list-item-group v-if="!matricula.estaDeletado" color="primary">
                            <v-list-item @click="() => fichaDeMatricula(matricula)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-file-pdf</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Ficha de matrícula</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>

                          <v-list-item-group
                            v-if="
                              !matricula.estaDeletado &&
                              $constants.situacoesMatriculaParaDeclaracaoTransferencia.includes(
                                matricula.situacao
                              )
                            "
                            color="primary"
                          >
                            <v-list-item @click="() => declaracaoTransferenciaMatricula(matricula)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-file-pdf</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Declaração de transferência</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>

                          <v-list-item-group
                            v-if="!matricula.estaDeletado && matricula.todasAprovadas"
                            color="primary"
                          >
                            <v-list-item @click="() => certificadoConclusao(matricula)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-file-pdf</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Certificado de conclusão</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                        <v-divider></v-divider>
                        <v-list>
                          <v-list-item-group v-if="!matricula.estaDeletado" color="primary">
                            <v-list-item @click="() => editarMatricula(matricula)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-edit</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Editar matricula</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                        <v-list>
                          <v-list-item-group v-if="!matricula.estaDeletado" color="primary">
                            <v-list-item @click="() => recuperarMatricula(matricula)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-sync</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Recuperar matricula</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>

                          <v-list-item-group v-if="!matricula.estaDeletado" color="primary">
                            <v-list-item @click="() => deletarMatricula(matricula)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-trash</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Apagar matricula</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                        <v-divider></v-divider>
                      </v-card>
                    </v-menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="listarMatriculas"
          :search="search"
        ></e-paginate>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import Matricula from "@/Models/Matricula";

export default {
  data() {
    return {
      carregandoMatriculas: false,
      dataTableFilter,
      search: "",
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      matriculasList: [],
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Código", value: "codigo" },
          { text: "Aluno", value: "nomeAluno" },
          { text: "Turma", value: "nomeTurma" },
          { text: "Situação", value: "situacao" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  async mounted() {
    this.carregandoMatriculas = true;
    this.$loaderService.open("Carregando Matriculas");
    this.listarMatriculas(this.pageNumber);
    this.carregandoMatriculas = false;
    this.$loaderService.close();
  },
  watch: {
    carregandoMatriculas(value) {
      if (value && this.matriculasList.length === 0) {
        this.$loaderService.open("Carregando matriculas");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      if (val.length > 2) {
        await this.listarMatriculas(0, val);
      }
      if (!val) {
        await this.listarMatriculas(0);
      }
    },
  },
  methods: {
    async listarMatriculas(pageNumber, query = null) {
      this.carregandoMatriculas = true;
      try {
        const response = await this.$services.matriculasService.syncAll(pageNumber, query);
        this.matriculasList = response.data.map((matricula) => {
          const todasAprovadas = matricula.avaliacoes_circuito.every(
            (avaliacao) => avaliacao.valor_texto === "APROVADO"
          );
          return {
            ...new Matricula(matricula),
            todasAprovadas,
          };
        });
        this.paginate = response;
      } catch (error) {
        this.$handleError(error);
      }
      this.carregandoMatriculas = false;
    },
    criarMatricula() {
      this.$router.push({ name: "matriculas.create" });
    },
    editarMatricula(matricula) {
      this.$router.push({
        name: "matriculas.edit",
        params: { matricula_id: matricula.id },
      });
      // return matricula;
    },
    async deletarMatricula(matricula) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta matricula?",
        confirmationCode: matricula.id,
        confirmationMessage: `Por favor, digite <strong>${matricula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Matricula");
              try {
                await this.$services.matriculasService.deletarMatricula(matricula);
                this.$toast.success("Matricula deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarMatricula(matricula) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta matricula?",
        confirmationCode: matricula.id,
        confirmationMessage: `Por favor, digite <strong>${matricula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Matricula");
              try {
                await this.$services.matriculasService.recuperarMatricula(matricula);
                this.$toast.success("Matricula recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },

    async declaracaoConclusao(matricula) {
      this.$loaderService.open("Baixando declaração");
      try {
        await this.$services.relatoriosService.declaracaoConclusao(matricula.id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async fichaDeMatricula(matricula) {
      this.$loaderService.open("Baixando ficha de matricula");
      try {
        await this.$services.relatoriosService.fichaDeMatricula(matricula.id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async certificadoConclusao(matricula) {
      this.$loaderService.open("Baixando declaração");
      try {
        await this.$services.relatoriosService.certificadoConclusao(matricula.id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async declaracaoTransferenciaMatricula(matricula) {
      this.$loaderService.open("Gerando declaração de transferência de matricula");
      try {
        await this.$services.relatoriosService.declaracaoTransferenciaMatricula(matricula.id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async declaracaoCurso(matricula) {
      this.$loaderService.open("Baixando declaração");
      try {
        await this.$services.relatoriosService.declaracaoCurso(matricula.id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
