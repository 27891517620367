import Axios from "@/plugins/Axios";

export class RelatoriosService {
  async listarTurma(turma) {
    const response = await Axios()
      .get(`relatorios/listarTurma/${turma}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async boletimGeral(turma) {
    const response = await Axios()
      .get(`relatorios/boletimGeral/${turma}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        // const link = document.createElement("a");
        // link.href = window.URL.createObjectURL(blob);
        // link.download = `boletim_turma_${turma}.pdf`;
        // link.click();
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatorioBNCC(matricula_id) {
    const response = await Axios()
      .get(`relatorios/relatorioBNCC/${matricula_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        // const link = document.createElement("a");
        // link.href = window.URL.createObjectURL(blob);
        // link.download = `ralatorio_bncc_matricula_${matricula_id}.pdf`;
        // link.click();
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async planoMensal(gestao_id, mes_id, disciplina_id) {
    const response = await Axios()
      .get(`relatorios/plano-mensal/${gestao_id}/${mes_id}/${disciplina_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async boletimIndividual(matricula_id) {
    const response = await Axios()
      .get(`relatorios/turmaBoletimIndividual/${matricula_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        // const link = document.createElement("a");
        // link.href = window.URL.createObjectURL(blob);
        // link.download = `boletim_individual_matricula_id_${matricula_id}.pdf`;
        // link.click();
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatorioAee(matricula_id) {
    const response = await Axios()
      .get(`relatorios/relatorio-aee/${matricula_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async alunosPorUnidadeEScolar(franquia_id) {
    const response = await Axios()
      .get(`relatorios/alunos_por_unidade/${franquia_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async boletinsFranquia(franquia_id) {
    const response = await Axios()
      .get(`relatorios/boletins-franquia/${franquia_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatoriosBnccFranquia(franquia_id) {
    const response = await Axios()
      .get(`relatorios/relatorios-bncc-franquia/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async escolasTurmasAlunos() {
    const response = await Axios()
      .get("relatorios/escolas_turmas_alunos", { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async listarTodasTurmas() {
    const response = await Axios()
      .get("relatorios/listar-todas-turmas", { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async listarTurmasFranquia(franquia_id) {
    const response = await Axios()
      .get(`relatorios/listar-turmas-franquia/${franquia_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async inscritosCongresso() {
    const response = await Axios()
      .get("relatorios/inscritos-congresso", { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "inscritos_congresso.pdf";
        link.click();
      });
    return response;
  }

  async diarioFrequencia(gestao_id, mes, tipoEnsino) {
    const { id } = mes;
    const response = await Axios()
      .get(`relatorios/diario-frequencia/${gestao_id}/${id}/${tipoEnsino}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `diario_frequencia_${gestao_id}.pdf`;
        link.click();
      });
    return response;
  }

  async diarioAulas(gestao_id, mes, tipoEnsino) {
    const { id } = mes;
    const response = await Axios()
      .get(`relatorios/diario-aulas/${gestao_id}/${id}/${tipoEnsino}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `diario_aulas_${gestao_id}.pdf`;
        link.click();
      });
    return response;
  }

  async diarioNotas(gestao_id) {
    const response = await Axios()
      .get(`relatorios/diario-notas/${gestao_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatorioBNCCTurma(turma_id) {
    const response = await Axios()
      .get(`relatorios/relatorio-bncc/${turma_id}/turma`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `ralatorio_bncc_turma_${turma_id}.pdf`;
        link.click();
        return response;
      });
  }

  async insercaoAulas() {
    const response = await Axios()
      .get("relatorios/insercao-aulas", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async lotacaoGeral() {
    const response = await Axios()
      .get("relatorios/relatorio-lotacao-geral", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async lotacao() {
    const response = await Axios()
      .get("relatorios/relatorio-lotacao", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async lotacaoEscola(franquia_id) {
    const response = await Axios()
      .get(`relatorios/relatorio-lotacao-escola/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async planoBimestral(gestao_id, bimestre, disciplina_id) {
    const response = await Axios()
      .get(`relatorios/plano-bimestral/${gestao_id}/${bimestre}/${disciplina_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async resultadoPorDisciplina(turma) {
    const response = await Axios()
      .get(`relatorios/resultado-por-disciplinas/${turma}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async capaDiarioClasse(gestao_id) {
    const response = await Axios()
      .get(`relatorios/capa/diario-classes/${gestao_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async resumoMovimentoEscolar(franquia_id) {
    const response = await Axios()
      .get(`relatorios/resumo-movimento-escolar/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async declaracaoConclusao(matricula_id) {
    const response = await Axios()
      .get(`relatorios/declaracao-conclusao/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async declaracaoCurso(matricula_id) {
    const response = await Axios()
      .get(`relatorios/declaracao-curso/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async fichaDeMatricula(matricula_id) {
    const response = await Axios()
      .get(`relatorios/ficha-matricula/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async declaracaoTransferenciaMatricula(matricula_id) {
    const response = await Axios()
      .get(`relatorios/declaracao-transferencia-matricula/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async documentoJustificativaFalta(aula_id, matricula_id) {
    const response = await Axios()
      .get(`relatorios/falta/baixar-documento/${aula_id}/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async frenquenciaIndividual(matricula_id, mes) {
    const response = await Axios()
      .get(`relatorios/frenquencia-individual/${matricula_id}/${mes}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatorioAuxiliar(gestao_id, disciplina_id, mes) {
    const response = await Axios()
      .get(`relatorios/relatorio-auxiliar/${gestao_id}/${disciplina_id}/${mes}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async planoBimestralInfantil(gestao_id, bimestre) {
    const response = await Axios()
      .get(`relatorios/plano-bimestral-infantil/${gestao_id}/${bimestre}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async planoMensalInfantil(gestao_id, mes) {
    const response = await Axios()
      .get(`relatorios/plano-mensal-infantil/${gestao_id}/${mes}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatorioDeListagemDosPlanosLancados(franquia_id) {
    const response = await Axios()
      .get(`relatorios/historico-lancamentos-planos/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatorioDeListagemDosPlanosLancadosGeral() {
    const response = await Axios()
      .get(`relatorios/historico-lancamentos-planos-geral`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatoriosEvasao(franquia_id) {
    const response = await Axios()
      .get(`relatorios/evasao/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async alunosNaoMatriculados(franquia_id) {
    const response = await Axios()
      .get(`relatorios/alunos-nao-matriculados/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async alunosNaoMatriculadosGeral() {
    const response = await Axios()
      .get(`relatorios/alunos-nao-matriculados-geral`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async qtdProfessoresPorDisciplina() {
    const response = await Axios()
      .get(`relatorios/professores-por-disciplina`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatoriosCensoFranquia(franquias_id) {
    const response = await Axios()
      .get(`relatorios/censo/${franquias_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatorioDiarioAtendimento(matricula_id, gestao_id) {
    const response = await Axios()
      .get(`relatorios/relatorio-diario-atendimento/${matricula_id}/${gestao_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatorioEspecialDesenvolvimentoAnual(matricula_id, gestao_id, semestre) {
    const response = await Axios()
      .get(
        `relatorios/relatorio-especial-desenvolvimento-anual/${matricula_id}/${gestao_id}/${semestre}`,
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatorioEspecialDesenvolvimentoBimestral(matricula_id, gestao_id, bimestre_id) {
    const response = await Axios()
      .get(
        `relatorios/relatorio-especial-desenvolvimento-bimestral/${matricula_id}/${gestao_id}/${bimestre_id}`,
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async certificadoConclusao(matricula_id) {
    const response = await Axios()
      .get(`relatorios/certificado-conclusao/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
  }

  async gerarAtaFinal(turma) {
    const response = await Axios()
      .get(`relatorios/ataTurma/${turma}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `ata_turma_${turma}.pdf`;
        link.click();
      });
    return response;
  }
}
export default new RelatoriosService();
